.page-header-bg{
	img{
	// 	@extend .col-md-2;
	// @extend .col-xs-4;
	// @extend .offset-xs-4;
	// @extend .offset-md-0;
		display: block;
		margin: 0 auto;
		max-height: 140px;
		width: auto;
		padding: $prc-padding;
	}

	h1{
		color: white;
		text-align: center;
		font-size: $font-size * 2;
		// margin: 10px;
		padding-bottom: 15px;
	}
}

.page-header{
	h1{
		color: $brand-primary;
	}


}