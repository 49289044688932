// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #f39c12;


$prc-primary: #f39c12;
$prc-secondary: #2ecc71;
$basic-font-color: #333;

$prc-padding: .5rem;

/*  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px*/

/*$md: 992px;*/
$xs: 543px;
$sm: 544px;
$sm-max: 767px;
$md: 768px;
$md-max: 991px;
$lg: 992px;
$xl: 1200px;

$green-blue: #4B7F80;
$grey: #666;
$full-width-mobile-font: 1.5rem;
$full-width-desktop-font: 1.2rem;

$font-size: 1rem; 
$line-height: $font-size * 1.5;

$font-primary: Gilroy, "Helvetica Neueu", Helvetica;

@media(max-width: $sm){ 
	html{
		/*font-size: .75rem !important;*/
	}
}

/* Patrick - These classes should be used semantically, not added to HTML */

/*SOFT HIDES*/
@media (max-width: 543px) {
  .hidden-xs-down-soft {
    display: none;
  }
}

@media (min-width: 544px) {
  .hidden-sm-up-soft {
    display: none;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down-soft {
    display: none;
  }
}

@media (min-width: 768px) {
  .hidden-md-up-soft {
    display: none;
  }
}

@media (max-width: 991px) {
  .hidden-md-down-soft {
    display: none;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up-soft {
    display: none;
  }
}  

@media (max-width: 1199px) {
  .hidden-lg-down-soft {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up-soft {
    display: none;
  }
}


$logo-url: url('../images/logo_0.png');
