/* Header */

.header-content{
	margin: $prc-padding 0;
	@extend .row;

	display: flex;
	align-items: center;

}
.brand{
	max-width: 200px;
	margin: auto;
	@extend .col-xl-2;
	@extend .col-lg-2;
	@extend .col-md-2;
	@extend .col-sm-3;
	@extend .col-xs-4;

	text-align: left;

	span{
		display: none;
	}

	img{
		@media(min-width: $md){
			max-width: 100%;
		}
		@media(max-width: $sm-max){
			max-width: 100%;
		}
	}

} 

/*DROPDOWN MENU*/
.drop-activate{
	@extend .hidden-lg-up;  
	@extend .col-sm-9;
	@extend .col-xs-6; 
	@extend .offset-xs-2;
	@extend .offset-sm-0;
	@extend .text-md-right;
	@extend .text-xs-right;
	@extend .text-sm-right;
	padding: $prc-padding * 2;
	font-size: $font-size * 1.5;
	text-decoration: none !important;
	text-align: right;
	color: $prc-primary;
}

.menu-item.dropdown{
	.dropdown-menu{
		margin-top: 22px;
		padding: 0; 
		display: none;
		border: none;
		border-radius: 0;
		padding: .5rem;
		li{
			display: block;
			width: 100%;
			a{
				display: block;
				padding: .5rem;
				margin: 0;
				/*font-size: 1rem;*/
				width: 100%;
			}
		}
	}
	&.open{
		.dropdown-menu{
			display: block;
		}
	}
	@media screen and (max-width: $sm-max){
		.menu-item.dropdown{
			position: relative;
		}
		.dropdown-menu{
			width: 100%;
			position: relative;
			text-align: center;
		}
	}
	@media screen and (min-width: $sm + 1){

	}
}

header.banner{
	/*border-bottom: 2px solid $brand-primary;*/
	// box-shadow: -3px 3px 5px rgba(0,0,0,0.8);

}

.nav-primary{
	padding: 0;
	@extend .col-xl-10;
	@extend .col-lg-10;
	@media(max-width: $xl) and (min-width: $lg){
		/*padding-top: $prc-padding;*/
	}
	ul{
		padding-left: 0;
		display:inline-block;
		margin-bottom: 0;
		padding-top: 0;
		// padding-top: 10px;
		// padding-bottom: 10px;
		/*margin-bottom: 20px;*/
	}

	li{
		/*margin: 0 5px;*/
		// @extend .col-md-1;
		display: inline-block;
		a{
			/*margin-right: 5px;*/
		}		
	}

	a{
		// font-family: "San Francisco";
		font-weight: lighter;
		font-size: $font-size * .8;
		/*font-weight: bold;*/
		/*text-transform: uppercase;*/
		color: $brand-primary;
		// text-decoration: underline;
	}

	@media (min-width: $xl){

		li{
			/*margin: 0 $prc-padding;*/
		}
	}

	@media (min-width: $lg){
		ul{
			/*padding-top: $prc-padding;*/
		}
		li{
			a{
				padding: 0 $prc-padding; 
			}
		}
	}	

	@media(min-width: $lg){
		text-align: right;
		display: block !important;
	}

	@media(max-width: $md-max){
		text-align: center;
	}

	@media (max-width: $md-max){
		ul{
			padding-top: $prc-padding;
			margin-top: $prc-padding;
			border-top: 1px solid #ddd;
			li{
				width: 100%;
				a{
					/*text-transform: uppercase;*/
					padding: $prc-padding;
					display: block;
					font-size: $font-size * 1.5;
				}
			}
		}
	}		
}