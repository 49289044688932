// [css-above-the-fold]


@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";  

// $prc-jumbo-padding: $prc-padding * 10;

$prc-jumbo-padding: $prc-padding * 5;



.text-center{
	text-align: center;
}

html body{
	/*font-size: 1.2rem;*/
}

.row{
	align-items: center;
}

b,strong{
	font-weight: bold;
}

body, h1, h2, h3, h4, h5, h6, p, span, b, strong, i{
	font-family: $font-primary;
}

body, h1, h2, h3, h4, h5, h6, p, span{
	font-weight: normal;
}

p{
	margin-bottom: $prc-padding; 
}

.lighter-font{
	font-weight: lighter;
}
 
h2, h1{ 
	&.large{
		font-size: 48px;
		/*font-weight: lighter;*/
		@media (min-width: $md){
			font-size: 60px;
		}	
	}
}

li{
	list-style-type: circle;
}

.smallhead{
	display:none;
}

.article-page{
	border: 0px !important;
	position: relative;
	p{
	    line-height: $line-height;
	    font-size: 18px;		
	}
}

body{
	/*background:red;*/
}

.project-link{
	@extend .btn;
	@extend .btn-lg;
	@extend .btn-primary;

	/*display: block;*/
	margin: 0 auto;


}

.hover-effect{
	img{
		-webkit-transition: all .2s; /* Safari */
		transition: all .2s;	

		// Size these down so they don't expand the page	
    	@media (max-width: $sm-max){
    		max-width: 90% !important;
    	}	
	}
	&:hover img{
	    -ms-transform: scale(1.1); /* IE 9 */
	    -webkit-transform: scale(1.1); /* Safari */
	    transform: scale(1.1);

	}
}



.single, .category{
	.full-width-item{
		background-size: cover !important;
		/*padding: 15% 0 !important;*/
		background-position: center center !important;
		position: relative;
		border-bottom: 5px solid white;
		h1, a, p{
			color: white !important;
			position: relative;
			z-index: 10;
		}
		font-size: 24px;
		.btn{
			margin-left: 0px;
		}		
	}
}

.category{
	.article-page{
		margin-bottom: 0px;
		h2{
			text-align: center;
			text-transform: uppercase;
			/*font-weight: lighter;*/
			/*font-size: 48px;*/
		}
	}
	.hentry{
		background: #ccc;
	}	
}

.category{
	.full-width-item{
		/*background-color: black;*/
		overflow:hidden;
	}
}

.navbar-nav > li > a{
	font-size: 1rem;
	/*text-transform: uppercase;*/ 
}

.page-intro{
	display:none;
}

.logo{
	max-width: 250px;
	display:inline-block;
	img{

	}
}

.navbar{
	margin-bottom: 0px !important; 
}

.overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9;
    z-index: 0;
}

.brand{
	@extend .col-md-3;
	img{
		width: 100%;
		height: auto;
	}
} 

.full-width-features{
	color: white;
	.full-width-item{
		@media screen and (max-width: $md-max){

			h2{
				font-size: $full-width-mobile-font;

			}

			h4{
				font-size: $full-width-mobile-font * 0.75;
			}

			p{
				font-size: $full-width-mobile-font * 0.6;
			}

			.su-post-excerpt{
				margin-top: 20px;
			}
		}

		.breadcrumb{
			padding: 0px !important;
			li{
				color: white;
			}
			background: none;
			border: none;
		}		
	}
}

.full-width-item{
	position: relative;
    background-size: 70%;
    background-repeat: no-repeat;
	background-attachment: fixed;
    background-position: -30px;
	padding: 15%;
	.full-width-inner{
		z-index: 1;
	}
	p{
		margin-top: $prc-padding * 3;
		/*font-size: $font-size * 1;*/
		line-height: $line-height; 
		font-weight: lighter;
	}
}

.btn.btn-primary{
	color: white;
	&:hover{
		color: white;
	}
}

.body-padding{
	/*padding-top: 48px;*/
}

.owl-carousel{
	.owl-controls{
		background: #333;
		padding: 0px;
		position:relative;
		.owl-nav{
			margin-top: 0px;
			overflow:hidden;
			position: relative;
			z-index: 10;
		}
		.owl-prev,
		.owl-next{
			margin: 0px !important;
			padding: 15px !important;
			border-radius: 0px !important;
			background: #777 !important;
		}
		.owl-prev{
			float:left;
		}
		.owl-next{
			float:right;
		}
		.owl-dots{
			z-index: 0;
			position: absolute;
		    width: 100%;
		    top: 15px;			
		}
	}	
	.owl-item{
    	-webkit-backface-visibility: visible !important; /* Chrome, Safari, Opera */
   		backface-visibility: visible !important;		
		.owl-item-inner{
			position: relative;
			background-size: cover !important;
			background-repeat: no-repeat;
			padding: 20% 5%;
			text-align: center;
			background-position: center !important;
			/*margin: 5px;*/
			background: $green-blue;
			color: white;
			&.icon{
				.overlay{
					opacity: 1 !important;
					// min-height: 520px;
				}
				img{
					width: auto;
					margin: auto;
					max-height: 150px;
				}
			}
			a{
				color: white;
				&:hover{
					color: white;
				}
			}
			&.last{
				/*margin-left: 5px;*/
			}
			&.first{
				/*margin-right: 5px;*/
			}
			.content{
				z-index: 10;
				position: relative;
			}
			.overlay{
				//  Patrick Disabled this
			    // background-color: #dd4f33;
			    // z-index: 0;
			    // width: 100%;
			    // height: 100%;
			    // position: absolute;
			    // left: 0px;
			    // top: 0px;
			    // opacity: .9;
			}				
		}
	}
}

/*FEAUTURES*/
.features{
	padding: 20px; 
	ul{
		li{
			list-style: circle;
		}
	}
}

/*SINGLE*/
.article-page ul.meta{
	border: none;
}

.main{
/*	@extend .col-md-10;
	@extend .offset-md-2;*/
}

/*PULL QUOTE*/
.pull-quote{
	color: #666;
	font-size: 1.5em;
	line-height: $line-height;
	margin-bottom: 20px;
}



/* NEW SITE WORK */
.top-hero img{
	width: 100%;
}

.font-icon{
	// @extend .fa;
}

.service-header{

	color: white;
	/*background: linear-gradient( $brand-primary, lighten($brand-primary, 15%) );*/
	background: url('../images/orange_texture_bg.png');
	text-align: center;
	margin: 0;
	padding: 2* $prc-padding $prc-padding;
	// text-shadow: 1px 1px 5px rgba(0,0,0,0.8);
	text-transform: capitalize;
	i{
		margin-left: $prc-padding * 2;
	}
	font-weight: lighter;
	/*border-top: 3px solid darken($brand-primary, 5%);*/

}

/*ALL SINGLE PAGES*/
.content-header{
	align-items: center;
	text-align: center;
	position: relative;
	flex-direction: column;
	padding: $prc-jumbo-padding 0;
	// padding: $prc-jumbo-padding 0;
	/*@extend .row; */
	h2{
		/*@extend .offset-md-1;*/
		/*@extend .col-md-5;*/
		
		/*font-size: $font-size * 2.5;*/
		// PRC Edit
		font-weight: bold;
		// font-weight: lighter;
		text-align: center;
	}
	&.dark-content{
		*{
			color: #666;
		}
	}
	&.light-content{
		*{
			color: white;
		}
	}
	h1, h2{
		/*max-width: 700px;*/
		margin: auto;
		display: block;
		clear: both;
	}
	h1, .featured-image{
		@extend .col-md-12;
	}
	h1{
		font-size: $font-size * 1.2 !important;
		line-height: $font-size * 1.8 !important;
	}
	h2{
		font-size: $font-size * 2.5 !important;
/*		@extend .col-md-8;
		@extend .offset-md-2;*/
	}	
	h3{
		font-size: $font-size * 1.3 !important;
		line-height: $font-size * 2 !important;
		font-weight: lighter;
		/*margin-bottom: $font-size * 2;		*/
		text-align: center;
	}
	.content-header__content{
/*		max-width: 90%;
		width: 800px;*/
		@extend .col-md-8;
		@extend .offset-md-2;
	}
	.content{
		text-align: left;
		margin: auto;
/*		@extend .col-md-8;
		@extend .col-sm-10;
		@extend .offset-sm-1;
		@extend .offset-md-2; */
	}
	*{
		color: white;
		font-weight: lighter;
	}
	.featured-image{
		margin-bottom: 2.5rem;
		@extend .col-md-6;
		/*@extend .hover-effect;*/
		/*text-align: left;*/
		img, i{
			width: auto;
			max-height: 300px;
			/*max-width: 800px;  */
			/*width: 90%; */
			/*height: auto;*/

			@media (max-width: $sm-max){
				max-width: 90%;
			}

		}
		.font-icon{
			font-size: $font-size * 10;
			color: white;
			/*opacity: .8;*/
		}
	}
	.brand{
		img{
			width: 100%;
		}
	}
	@media (max-width: $sm-max){
		padding: $prc-padding * 5 0;
		h2{
			font-size: $font-size * 2 !important;
		}
		h3{
			font-size: $font-size * 1.2 !important;
			/*line-height: $font-size * 1.4 !important;*/
		}
		h1{
			order: 0;
		}
		h2{
			order: 1;
		}
		h3, .end-section, .content{
			order: 3;
		}
		.featured-image{
			margin-bottom: 2.5rem;
			/*order: 2;*/
			img{
				/*padding: 2.5rem;*/
				max-height: 200px;
			}
		}
		/*@media (max-width: $md-max){*/
			.ion-chevron-down{
				margin-top: 2rem;
				order: 10 !important;
			}			
		/*}		*/
	}
}

/*.project-template-default{
	main */.content-header{
		/*&.has-background{*/
			background-image: url('../images/background.png'); 
		/*}*/
		background-size: 30% auto;
		background-repeat: repeat;
		background-attachment: fixed;	
		background-color: #eee;
/*		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;	*/	
	}	
/*}*/

main{
	flex: 0 0 100% !important;
	max-width: 100% !important;
	padding: 0 !important;
	.entry-content{
/*		@extend .col-md-10;
		@extend .offset-md-1;*/

		// PRC - this duplicates padding of flex content rows
		// margin-top: $prc-padding * 5;
		p, h1, h2, h3, h4, h5, span, b, li{
			color: $basic-font-color !important;
		}
		.content-inner{
			@extend .offset-lg-1;
			@extend .col-lg-9;
			&.has-sidebar{
				@extend .col-lg-7;
				@extend .offset-lg-1;
				@extend .col-xs-12;
				/*@extend .offset-xs-0; 				*/
			}						
		}
		.two-col-content{
			@extend .row;
			align-items: flex-start !important;
		}
		.sidebar{
			img{
				max-width: 100%;
				max-height: 300px; 
			}
			@media(max-width: $md-max){
				margin-top: $prc-padding * 5; 
			}
			@extend .col-lg-4;
			@extend .col-xs-12;
			*{
				font-size: 1.1rem;
				line-height: 1.5rem;
				/*color: white !important;*/
			}
			a{
				text-align: center;
				font-size: $font-size * .8;
				/*text-decoration: underline;*/
				&:hover{
					text-decoration: none;
				}
				img{

				}
			}
			.inner{
				padding: $prc-padding * 2;
				/*background: #f9f9f9;*/
				text-align: center;
				border-radius: $prc-padding * .5; 
			}
		}		
	}
}

.entry-content, .content-header, .prc-section--contact{
	h1, h2, h3, h4{
		clear: both;
		display: block;
		width: 100%;
	}
	h2{
		font-size: $font-size * 3;
		margin-top: $font-size; 
		padding-bottom: $font-size * .7;
		/*border-bottom: 1px solid #eee;*/

		/*// PRC*/
		/*font-weight: bold;*/
		/*font-weight: lighter;*/
	}
	h3{
		font-size: $font-size * 2;
		margin-top: $font-size * .7;
		padding-bottom: $font-size * .5;
		/*border-bottom: 1px solid #eee;*/
		font-weight: lighter;
	}
	h4{
		font-size: $font-size * 1.7;
		margin-top: $font-size * .5;
		padding-bottom: $font-size * .3;
		/*border-bottom: 1px solid #eee;*/
		// font-weight: lighter;
				font-weight: bold;

	}
	h5{
		font-size: $font-size * 1.4;
		margin-top: $font-size * .4;
		padding-bottom: $font-size * .3;
		/*border-bottom: 1px solid #eee;*/
		// font-weight: lighter;
				font-weight: bold;

	}	
	p, li{
		/*line-height: $line-height;*/
		font-weight: lighter;
		font-size: 1.1rem;
		line-height: 2em;

	}	
/*	@media(max-width: $sm-max){
		p, li{
			font-size: $font-size * 1.1;
			line-height: $font-size * 2;
		}
	}	*/
}
/*END ALL SINGLE PAGES*/

/*FLEX CONTENT*/
/* Temp FLexible Content Layout */
.flexible-content-row{
	/*padding: $prc-jumbo-padding 0;	*/ 
	/*@extend .row;*/
	/*@extend .hover-effect;		*/
	// position: relative;


	>.container{
		border-top: 1px solid #eee;
	}
	// padding-top: $prc-padding * 3;

	&.flexible-content-row--reduced-padding{

	}

	@media (min-width: $lg){
		&.image-right{
			.container > .row{
				flex-direction: row-reverse;
			}
		}
	}
	@media(max-width: $md-max){
		.content{
			/*text-align: justify;		*/
		}
	}
	&.image-top{
		.image{
			padding: $prc-padding * 2 0;
			text-align: center;
			@extend .col-md-6;
			@extend .offset-md-3;
		}
		.content{
			@extend .col-md-8;
			@extend .offset-md-2;
		}
		h5, p{
			text-align: center;
		}
		img{
			width: 100%;
			max-width: 350px;
			height: auto;
		}
	}
	img{
		max-width: 100%;
	}
	p{
		/*line-height: $font-size * 1.5 !important;*/
	}
	h5{
		font-size: $font-size * 1.3;
		line-height: $font-size * 2;
		font-weight: lighter;
		margin-bottom: $prc-padding * 2;
	}
	.image.mobile{
		@extend .hidden-lg-up;
		@extend .col-md-6;
		@extend .offset-md-3;		
	}
	@media(max-width: $md-max){
		.image-side, .image.mobile, .image.top{
			text-align: center;
			margin: 2rem auto;
			img{				
		    width: auto;
		    margin: auto;
		    max-width: 100%;
		    max-height: 300px;
		    height: auto;
			}	
		}	
	}
	@media(max-width: $sm-max){
		.image-side, .image.mobile, .image.top{
			img{
				max-height: 200px;
				padding-bottom: $prc-padding * 3;
			}
		}
		/*padding: $prc-padding * 5 0;*/ 
	}
	&:first-child{
		border-top: none; 
	}	
	.container{
		.row{
			padding-top: 3rem;
			padding-bottom: 3rem;
			/*margin-bottom: 3rem;*/
		}
	}
	&.flexible-content-row--reduced-padding-top{
		.container{
			.row{
				padding-top: 1rem;
			}
		}
	}
	&.flexible-content-row--reduced-padding-bottom{
		.container{
			.row{
				padding-bottom: 1rem;
			}
		}
	}	
	@media(max-width: $md-max){
		.image.mobile{
			/*margin-bottom: 1rem;*/
		}
	}	
	&.flexible-content-row--text-align-left{
		p{
			text-align: left;
		}
	}
	.nf-form-cont{
		margin-top: 0;
		label{
			font-size: 1rem;
			line-height: 1.5em;
		}
		input[type=email], 
		input[type=text], 
		textarea{
			border: 1px solid #ccc;
			min-height: 40px;
		}
		.nf-field-container{
			margin-bottom: 1rem;
		}
		.field-wrap{
			label{
				/*font-size: .875rem;*/
			}
		}
		.nf-field-label{
			margin-bottom: .25rem;
			label{
				font-size: 1rem;
				font-weight: bold !important; 
			}
		}
		input[type=checkbox],
		input[type=radio]{
			margin-top: .25rem;
		}
	} 
	.ninja-forms-req-symbol{
		color: $prc-primary !important;
	}
} 

img.chevron-icon{
	max-width: 4em;
	height: auto;
	opacity: .5;
	padding: $prc-padding * 2;
	display: inline-block;
}

.image-side, .stats{
	@extend .col-lg-6;
	@extend .col-md-8;
	@extend .offset-lg-0;
	@extend .offset-md-2;
	/*@extend .col-sm-12;*/
	/*min-height: 300px;*/
	// position: absolute;
	align-items: center;
	justify-content: center;

	top: 0;
	bottom: 0;
}

.image-side{
	@extend .hidden-md-down;
	img{
		display: block;
		/*max-width: 100%;*/
		/*max-height: 400px;*/
/*		// width: auto;
		// max-height: 100%;*/
		height: auto;
		padding: 5%;
		margin: 0 auto;
	}		
}

.header, .content-container{
	&.side{
		@extend .col-lg-6;
		@extend .col-md-12;
		/*@extend .offset-md-2;*/
		.content{
			@extend .col-lg-12;
			@extend .col-md-8;
			@extend .offset-lg-0;
			@extend .offset-md-2;
			@extend .col-sm-8;
			@extend .offset-sm-2;
		}
		.image{
			@extend .col-sm-8;
			@extend .offset-sm-2;
		}
		.header{
			@extend .col-md-12;			
			@media(max-width: $md-max){
				text-align: center;	
			}
		}
	}
	&.block{
		@extend .col-md-12;
		text-align: center;	 
	}
	h3{
		font-size: $font-size * 1.2 !important;
	}
	h4{
		font-size: $font-size * 2.5 !important;
	}		
	h2, h3, h4{
		border: none !important;
	}
	h4{
		border-bottom: none !important;
		/*font-weight: bold;*/
	}
}
/*END FLEX CONTENT*/


.end-section{
	@extend .col-md-12; 
	text-align: center;
	padding-top: $prc-padding * 3;
	text-transform: uppercase;
	font-size: $font-size * .8;
	font-weight: lighter;
	/*letter-spacing: 2px;*/
	/*opacity: .7; */
	/*text-shadow: 0 -1px 0 rgba(0, 0, 0, .5);*/
}


.home .main{
	padding: 0;
}


.block-list{
	@extend .row;


	/*padding-left: 15px;*/
	// padding-right: 15px;
	margin-bottom: 0;

	// padding-left: 20px;
	list-style: none;
	align-items: stretch;
	.block-item{
/*		display: flex;
		align-items: center;
		justify-content: center;
		padding: 100px 10px;*/	
		@extend .hover-effect;

		.list-content-container{


		}

		a{
/*			display: flex;
			text-align: center;
			position: absolute;
			width: 100%;
			top: 0;
			bottom: 0;*/

			position: relative;
			/*color: white;*/
			display: block;
			flex-direction: column;
			padding: 1rem 0;
			text-align: center;
			/*letter-spacing: 2px;  */
			// font-weight: lighter;
			font-weight: bold;
			// text-transform: uppercase;
			z-index: 20;			 
			img{
				margin: auto;
				margin-bottom: $prc-padding * 2;				
			}
			span, h2{
				/*font-weight: lighter;*/
				// font-size: $font-size * .75;
				 font-size: $font-size * 1.5;
	 			font-weight: bold;
				-webkit-font-smoothing: antialiased;

			}
			&:hover{
				text-decoration: none;
			}
			@media(max-width: $sm-max){
				padding: 1rem 0;
			}
		}
		.block-item__image{
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 150px; 
			/*margin-bottom: 1rem;*/
		}
		&.dark-content{
			h2,a{
				color: #666;
			}
		}
		&.light-content{
			h2,a{
				color: white;
			}
		}
		h2, h2 a, p{
			color: white;
			text-align: center;
		}
		h2{
			/*font-weight: lighter;*/
			font-size: $font-size;
			/*letter-spacing: 2px;*/
		}
		h3{
			font-size: 1rem;
			color: #666;
			/*letter-spacing: 0;*/
		}
		/*background: red;*/
		list-style: none;
		@extend .col-md-4;
		img, .font-icon{
			// display: block;
			/*margin: $prc-padding auto;*/
			max-width: 100%;
			width: 300px;
			height: auto;
			/*max-height: 300px;*/
		}
	}
	.content{
		text-align: center;
	}
	.font-icon{
		font-size: $font-size * 5.3;
		max-height: none;
		color: white;
		margin: auto;
	}
	.overlay{
		position: relative;
		z-index: 20;
	}
	.overlay-bg{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		opacity: .8;
		z-index: 10;
	}
}

.round-button{
	padding: 1rem 2rem;
	font-weight: bold;
	background: #f39c12;
	display: inline-block;
	border-radius: 100px;	
	color: white !important;
	letter-spacing: 1px;
	br{
		display: none;
	}
}

.tried-the-rest{
	padding: 5rem 0 !important;
	background-image: none;
	h1, h2, h3, p, .ion-chevron-down{
		color: #666 !important;
	}
	.featured-image{
		display: none;
	}
	.content{
		text-align: center;
		margin: auto;
	}
	.round-button{
		margin-top: 1rem;
	}
}

/* Wrapper */
.wrap{
	max-width: none;
	margin: 0;
	padding: 0;
	/*@extend .container;*/
}

.content-container{

}

/* Testimonial on Project  Page */
.testimonial{
	color: $grey;
	font-family: "Georgia";
	font-size: $font-size * 1; 
	line-height: $font-size * 1.8; 
	font-weight: lighter;
}


.testimonial-header{
	font-weight: bold;
	margin-top: $prc-padding * 3;
}

/*PROJECTS ON SERVICES PAGE*/
.related-projects{
	padding-bottom: $prc-jumbo-padding;
	@extend .row;
	.project{
		@extend .col-lg-4;
		@extend .col-md-12;
		@extend .col-sm-12;
		.testimonial{
			color: $grey;
			font-family: "Georgia";
			font-size: $font-size * 1; 
			line-height: $font-size * 1.8; 
			font-weight: lighter;
		}
	}
	&.testimonials{
		align-items: flex-start;
		padding: $prc-padding * 2.5;
		@media(max-width: $md-max){
			.project{
				margin-bottom: $prc-padding * 3; 
			}
		}		
	}
}

/*SIMPLE LINKLIST WITH NUMBERS*/
.stats{
	text-align: center;
	.big-number{
		font-size: 5em;
		font-weight: bold;
		display: inline-block;
		width: auto;
		cursor: help;
	}
}

/*SINGLE PROJECT*/
.single-project{
	.entry-content{
		margin-top: 0;
		padding: $prc-padding * 5 0;
	}
	.project-link{
		margin-top: $prc-padding * 4;
		border-radius: 100px;
	}
}

/*NINJA FORM*/
.nf-form-cont{
	margin-top: $prc-jumbo-padding;
	input[type="text"], input[type="email"], textarea{
		background-color: transparent;
		border: 2px solid white;
		border-radius: 3px;
		padding: 5px;
	}
	input[type="submit"]{
		border: 2px solid white;
	}
	.ninja-forms-required-items{
		margin-bottom: $prc-padding;
	}
	label{
		font-weight: lighter !important;
	}
	font-weight: lighter;

	.ninja-forms-field-error{
		// background: rgba(0,0,0,0.6);
		// border-radius: 0 0 3px 3px;

	}

	.ninja-forms-success-msg, .ninja-forms-error-msg{
		background: rgba(0,0,0,0.6);
		padding: $prc-padding; 
		border-radius: 3px;

		p{
			font-size: 1.56rem !important;
			text-align: center;
			margin-bottom: 0;
		}
	}
}

/*FLEXIBLE CONTENT ROW*/

footer.content-info{
	background: #333;
	color: white;
	text-align: center;
	font-size: $font-size * .75;
	padding: $prc-padding * 2 0;
	font-weight: lighter;
	.copyright{

	}
	.questions{
		margin-top: $prc-padding;
	}
}

.prc-section--contact{
	border-top: 1px solid #eee;
	/*margin-top: 3rem;*/
	padding-top: 3rem;
	&.prc-section--contact-content{
		margin-top: 0;
		padding-top: 0;
		border-top: none;
	}
	ion-icon{
		font-size: 2rem;
		margin-bottom: 1rem;
	}
	.row{
		align-items: stretch;
	}
	.inner{
		/*max-width: 400px;*/
		/*padding: 1rem;*/
		text-align: center;
	}
	.content-header__content{
		text-align: center;
	}
	.col{
		@extend .col-md-6;
		@extend .col-sm-6;
		@extend .col-xs-6;
/*		margin: 0;
		padding: 0;*/
		/*height: 100%;*/
		.inner{
			height: 100%;
		}
	}
	.mobile--hidden{
		@media(max-width: $md-max){ 
			display: none;
		}
	}
	a{
		/*display: block; */
		/*padding: 2rem 0;*/
		/*height: 100%;*/
		.overlay{
			position: absolute; 
			background: rgba(0, 0, 0, .3);
			opacity: 0;
			z-index: 0;
			width: 100%;
			height: 100%;
		}
		&:hover{
			text-decoration: none;
			.overlay{
				opacity: .2;
			}			
		}		
	}
	h2{
		font-weight: bold;
		/*font-size: 1.5rem;*/
		/*margin-bottom: 1rem;*/
	}
	h3{
		/*font-size: 1.25rem;*/
		/*margin-bottom: 1rem;*/
	}
	h4{
		/*font-size: 1rem;*/
		font-weight: bold;
	}
	.contact-method__image{
		margin-bottom: 1rem;
		img{
			max-height: 100px;
			width: auto;
			@media(max-width: $sm-max){
				max-height: 75px;
			}
		}
	}
	.contact-header__image{
		margin-bottom: 1rem;
		img{
			max-height: 150px;
			width: auto;
		}
		@media(max-width: $sm-max){
			img{
				max-height: 100px;
			}
		}		
	}
	.contact-methods__item{
		padding: 2rem;
		@media(max-width: $sm-max){
			padding: 2rem 0; 
		}
		height: 100%;
		img{
			-webkit-transition: all .2s; /* Safari */
			transition: all .2s;				
		}
		&.active{
			background: #f2f2f2;
	    img{
	    	-ms-transform: scale(1.1); /* IE 9 */
	    	-webkit-transform: scale(1.1); /* Safari */
	    	transform: scale(1.1);
	    }
		}
	}
}

.home{
	.prc-section--contact{
		margin-top: 0;
	}
}

body.contact-us{
	.prc-section--contact{
		border-top: none;
	}
}



.compact-banner{
	padding: 1rem 0;
	font-size: .875rem;
	h3{
		font-size: 1rem;
		font-weight: bold;
	}
	ion-icon{
		font-size: 3rem;
	}
	.compact-banner__inner{
		@media (min-width: $md){
			grid-template-columns: 1fr 15fr 2fr;
			align-items: center;
			column-gap: 20px; 
			display: grid; 
			.banner__cta{
				/*flex: 1 1 20%;*/
				text-align: right;
			}			
		}
		@media (max-width: $sm-max){
			text-align:center;
			.banner__image{
				margin: 1rem 0;
			}
			.banner__content{
				margin: 1rem 0;
			}
			.banner__cta{
				margin: 1rem 0;
			}
		}		
	}
	p{
		margin-bottom: 0;
	}
	.banner__image{
		/*flex: 1 1 50px;*/ 
	}
	.banner__content{
		/*flex: 1 1 100%;*/
	}

}

/*CUSTOM CONTENT*/
.custom-content{
	/*border-bottom: 1px solid #eee;*/
	/*padding-bottom: 3rem;*/
	.row{
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
	}
	.col{ 
		/*@extend .col-md-6;*/
		@extend .col-md-6;
		@extend .col-sm-8;
		@extend	.offset-sm-2;
		@extend .offset-md-0;
		margin-top: 1rem;
		/*@extend .col-xs-6; */
		img{
			height: 200px;
			width: auto;
			margin-bottom: 1rem;
		}
		.inner{ 
			/*padding: 1rem;*/
			text-align: center;
		}
	}
	.custom-content__image{
		width: 100%;
	}
	h4{
		font-size: 1.25rem;
	}
	font-size: 1rem;
	&.custom-content--padding-top{
		padding-top: 3rem;
	}
	&.custom-content--padding-bottom{
		padding-bottom: 3rem;
	}	
}




